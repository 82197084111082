import cn from 'classnames'
import React, { ComponentType, FC, useEffect, useState } from 'react'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { CommerceProvider } from '@framework'
import { IDeviceInfo, useUI } from '@components/ui/context'
import type { Page } from '@commerce/types/page'
import { Navbar } from '@components/common'
import type { Category } from '@commerce/types/site'
import CartSidebarView from '@components/cart/CartSidebarView'
import { WishlistSidebarView } from '@components/wishlist'
import { useAcceptCookies } from '@lib/hooks/useAcceptCookies'
import { Sidebar, Button, Modal, LoadingDots } from '@components/ui'
import { HomePageService } from 'services/homePage/index.service'
import s from './Layout.module.css'
import { getData } from '../../utils/clientFetcher'
import { setItem, getItem } from '../../utils/localStorage'
import NotifyUserPopup from '@components/ui/NotifyPopup'
import { LocalStorage } from '@components/utils/payment-constants'
import axios from '@services/axiosInterceptorInstance'
import { encrypt, decrypt } from '@framework/utils/cipher'
import {
  EmptyGuid,
  NEXT_GET_NAVIGATION,
  valueForLoginLogout,
} from '@components/utils/constants'
import Router from 'next/router'
import ProgressBar from '@components/ui/ProgressBar'
import LoginLogoutAskModal from '@components/account/LoginLogoutAskModal'
import { stringToBoolean } from '@framework/utils/parse-util'
import Cookies from 'js-cookie'
import {
  BTN_ACCEPT_COOKIE,
  GENERAL_COOKIE_TEXT,
} from '@components/utils/textVariables'
import { IExtraProps } from './Layout'
const Loading = () => (
  <div className="fixed z-50 flex items-center justify-center p-3 text-center w-80 h-80">
    <LoadingDots />
  </div>
)

const dynamicProps = {
  loading: Loading,
}

const FeatureBar: ComponentType<any> = dynamic(
  () => import('@components/common/FeatureBar'),
  {
    ...dynamicProps,
  }
)
const Footer = dynamic(() => import('@components/common/Footer'))

interface Props {
  pageProps: {
    pages?: Page[]
    categories: Category[]
  }
  nav: []
  footer: []
  isLocationLoaded: boolean
  config: any
  keywords: []
}

const ModalView: FC<{ modalView: string; closeModal(): any }> = ({
  modalView,
  closeModal,
}) => {
  return (
    <Modal onClose={closeModal}>{modalView === 'NOTIFY_USER' && null}</Modal>
  )
}
interface IProps {
  deviceInfo: IDeviceInfo
}
const ModalUI: FC<IProps> = ({ deviceInfo }) => {
  const { displayModal, closeModal, modalView, notifyUser, productId } = useUI()
  if (notifyUser) return <NotifyUserPopup deviceInfo={deviceInfo} />
  if (displayModal)
    return <ModalView modalView={modalView} closeModal={closeModal} />
  return null
}

const SidebarView: FC<
  { sidebarView: string; closeSidebar(): any } & IExtraProps
> = ({ sidebarView, closeSidebar, deviceInfo }) => {
  return (
    <Sidebar onClose={closeSidebar} deviceInfo={deviceInfo}>
      {sidebarView === 'CART_VIEW' && (
        <CartSidebarView deviceInfo={deviceInfo} />
      )}
      {sidebarView === 'WISHLIST_VIEW' && <WishlistSidebarView />}
    </Sidebar>
  )
}

const SidebarUI: FC<any> = ({ deviceInfo }: any) => {
  const { displaySidebar, closeSidebar, sidebarView } = useUI()
  return displaySidebar ? (
    <SidebarView
      sidebarView={sidebarView}
      closeSidebar={closeSidebar}
      deviceInfo={deviceInfo}
    />
  ) : null
}

interface LayoutProps {
  nav: []
  footer: []
}

const LayoutError: FC<Props & IExtraProps> = ({
  children,
  config,
  pageProps: { categories = [], ...pageProps },
  keywords,
  isLocationLoaded,
  deviceInfo,
}) => {
  const { isOnlyMobile } = deviceInfo
  const [showLoginLogoutModal, setShowLoginLogoutModal] = useState(false)
  const navTreeFromLocalStorage = getItem('navTree') || { nav: [], footer: [] }
  const [isLoading, setIsLoading] = useState(false)
  const { showSearchBar, setShowSearchBar, user, isGuestUser } = useUI()
  const isUserLoggedIn =
    (!isGuestUser && user?.userId && user?.userId !== EmptyGuid) || false

  const [data, setData] = useState(navTreeFromLocalStorage)

  const { appConfig, setAppConfig } = useUI()

  //check if nav data is avaialbel in LocalStorage, then dont fetch from Server/API
  useEffect(() => {
    const fetchLayout = async () => {
      try {
        const response: any = await getData(NEXT_GET_NAVIGATION)
        setData(response)
        setItem('navTree', response)
      } catch (error) {
        console.error(':: error ', error)
      }
    }
    fetchLayout()
    setAppConfig(config)
  }, [])

  useEffect(() => {
    Router.events.on('routeChangeStart', () => setIsLoading(true))
    Router.events.on('routeChangeComplete', () => setIsLoading(false))
    if (!Cookies.get(LocalStorage.Key.userEncryptedDetails)) {
      const access_token =
        localStorage?.getItem(LocalStorage.Key.userEncryptedDetails) ?? ''
      access_token &&
        Cookies.set(LocalStorage.Key.userEncryptedDetails, access_token)
    }
  }, [])

  const getTheNewLogin = async (mobileNumber: string) => {
    const detailedInfo = encrypt(
      JSON.stringify({
        username: mobileNumber,
      })
    )
    const data = {
      detailedInfo,
      // isChannelStore,
    }
    const res: any = await HomePageService.refreshPageContent({
      data: data,
    })
    const tempValue: any =
      res?.data?.result && JSON.parse(decrypt(res?.data?.result))
    if (tempValue?.userToken) {
      Cookies.set(LocalStorage.Key.userEncryptedDetails, tempValue?.userToken)
      localStorage.setItem(
        LocalStorage.Key.userEncryptedDetails,
        tempValue?.userToken
      )
    }
  }

  useEffect(() => {
    if (
      user?.userId &&
      isUserLoggedIn &&
      !localStorage?.getItem(LocalStorage.Key.userEncryptedDetails)
    ) {
      getTheNewLogin(user?.username)
    }
  }, [isUserLoggedIn, user?.userId])

  const askForLoginLogout = stringToBoolean(Cookies.get(valueForLoginLogout))
  // useEffect(() => {
  //   if (!showLoginLogoutModal) {
  //     setShowLoginLogoutModal(true)
  //   }
  // }, [askForLoginLogout])

  const { acceptedCookies, onAcceptCookies } = useAcceptCookies()
  const { locale = 'en-US', ...rest } = useRouter()

  const sortedData = data.nav.sort(
    (a: any, b: any) => a.displayOrder - b.displayOrder
  )

  return (
    <CommerceProvider locale={locale}>
      {/*TODO: Commented Engage*/}
      {/*<Script
        src="https://engage-asset.bettercommerce.io/_plugins/min/bc/v1/js/ch.js"
        strategy="beforeInteractive"
      />*/}

      {isLoading && <ProgressBar />}
      <div className={cn(s.root)}>
        <Navbar
          currencies={config?.currencies}
          config={sortedData}
          languages={config?.languages}
          key="navbar"
          keywords={keywords}
          deviceInfo={deviceInfo}
        />
        <main className="">{children}</main>
        <Footer config={data.footer} deviceInfo={deviceInfo} />
        <ModalUI deviceInfo={deviceInfo} />
        <SidebarUI deviceInfo={deviceInfo} />
        <FeatureBar
          title={GENERAL_COOKIE_TEXT}
          hide={acceptedCookies}
          action={
            <Button className="mx-5" onClick={() => onAcceptCookies()}>
              {BTN_ACCEPT_COOKIE}
            </Button>
          }
        />
      </div>
      {showLoginLogoutModal && askForLoginLogout && (
        <LoginLogoutAskModal
          deviceInfo={deviceInfo}
          modalTitle={'Relogin'}
          show={askForLoginLogout && showLoginLogoutModal}
          isOnlyMobile={isOnlyMobile}
          close={() => {
            setShowLoginLogoutModal(false)
            Cookies.set(valueForLoginLogout, '')
          }}
        />
      )}
    </CommerceProvider>
  )
}

export default LayoutError
